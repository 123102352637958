export const getAllCanais = (axios) => axios.get('unidade/canal')

export const addUnidade = (axios, payload) => axios.post('unidade', payload)

export const edtUnidade = (axios, payload) => axios.put('unidade', payload)

export const getByCodigo = (axios, unidade_codigo) => axios.get(`unidade/codigo/${unidade_codigo}`)

export const getAllLojasByUnidade = (axios, unidade_codigo) => axios.get(`unidade/loja/${unidade_codigo}`)

export const getAllLojasByUser = (axios) => axios.get(`unidade/loja/by/user`)

export const getByUser = (axios) => axios.get(`unidade/by/user`)